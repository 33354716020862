import axios from 'axios';

const v1 = axios.create({
  baseURL: process.env.REACT_APP_LAMBDA_FUNCTION_BASE_URL,
  headers: {
    accept: 'text/xml'
  }
});

const client =  {
  v1: v1,

  submitPodcastUrl: function(handle, email) {
    const encodedHandle = encodeURIComponent(handle);
    const encodedEmail = encodeURIComponent(email);
    return v1.get(`periscope-rss-generator?handle=${encodedHandle}&email=${encodedEmail}`).then(res => res.data)
  }
}

export default client;