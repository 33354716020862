import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Grid, TextField, FormControl, Button, CircularProgress } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import client from 'client';
import * as EmailValidator from 'email-validator';
import ReactGA from 'react-ga';
import './App.css';

const styles = theme => ({
  App: {
    textAlign: 'center'
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
  },
  pageBody: {
    marginTop: 100,
    paddingLeft: 10,
    paddingRight: 10
  },
  formContainer: {
    marginTop: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
      marginTop: 5,
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit
  },
  title: {
    marginBottom: 10
  },
  resultContainer: {
    marginTop: 30
  },
  smallTopMargin: {
    marginTop: 15
  },
  error: {
    color: 'red'
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize('UA-13134021-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.state = {
      handle: '',
      email: '',
      step: 1,
      podURL: '',
      copied: false,
      isLoading: false,
      errors: []
    }
  }



  isValidEmail = (email) => {
    if ( email === '' || email === null) {
      return false;
    } else {
      return !EmailValidator.validate(email);
    }
  }

  emailHelperText = (email) => {
    if(this.isValidEmail(email)) {
      return '';
    } else {
      return 'Please enter a valid email address.';
    }
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { handle, email } = this.state;
    this.setState(() => {
      return {
        isLoading: true,
        errors: []
      }
    }, () => {
      client.submitPodcastUrl(handle, email).then((res) => {
        this.setState(() => {
          return {
            podUrl: `${process.env.REACT_APP_LAMBDA_FUNCTION_URL}${handle}`,
            step: 2
          }
        }, ReactGA.event({ category: 'User', action: `Submitted Podcast URL for ${handle}` }))
      }).catch((err) => {
        let { errors } = this.state;
        errors.push(`There was a problem creating a podcast for ${handle}. Please try again with a different user.`);
        this.setState(() => {
          return {
            isLoading: false,
            errors: errors
          }
        }, ReactGA.event({ category: 'User', action: `Error Submitting Podcast URL for ${handle}` }))
      });
    });
  }

  resetState = (event) => {
    event.preventDefault();
    this.setState(() => {
      return {
        step: 1,
        handle: '',
        email: '',
        isLoading: false,
        copied: false,
        errors: []
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { step, podUrl, isLoading, errors } = this.state;
    return (
      <div className={classes.App}>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Toolbar>
              <div className={classes.grow}>
                <a href="/" rel="noopener noreferrer">
                  <Typography variant="h6" color="inherit" align="left">
                    Podscope
                  </Typography>
                </a>
              </div>
              <Typography variant="subtitle1" color="inherit">
                Made for <span role="img" aria-label="fun" aria-labelledby="header">🤪</span> by <a href="https://twitter.com/kneelshah" target="_blank" rel="noopener noreferrer">me.</a>
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.pageBody}>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item xs={12} md={8}>
                <Typography className={classes.title} variant="h2" color="inherit">Podscope</Typography>
                <Typography variant="h6" color="inherit">
                  Convert your favorite Periscope Streams into Podcasts
                </Typography>
              </Grid>
              <Grid container justify="center" spacing={16}>
                {step === 1 ?
                  <Grid item xs={12} md={4} container className={classes.formContainer} justify="center">
                    <Grid item xs={12} container direction="row" spacing={16}>
                      {errors.length >=1 &&
                        errors.map((error, idx) => {
                          return (
                            <Grid item key={idx}>
                              <Typography variant="subtitle2" className={classes.error}>{error}</Typography>
                            </Grid>
                          );
                        })
                      }
                    </Grid>
                    <form onSubmit={this.handleSubmit} className={classes.form}>
                      <TextField
                        required
                        label="Periscope Handle"
                        variant="outlined"
                        helperText="www.periscope.tv/ScottAdamsSays"
                        value={this.state.handle}
                        onChange={this.handleChange('handle')}
                        margin="normal"
                        placeholder="i.e. ScottAdamsSays"
                        className={classes.textField}
                        id="outlined-handle"
                        fullWidth
                      />
                      <TextField
                        error={this.isValidEmail(this.state.email)}
                        variant="outlined"
                        value={this.state.email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        className={classes.textField}
                        id="outlined-email"
                        fullWidth
                        placeholder="name@company.com"
                        label="Email Address"
                        helperText={this.emailHelperText(this.state.email)}
                      />
                      <FormControl margin="normal" fullWidth>
                        <Button type="submit" variant="contained" color="secondary"
                            className={classes.submit}
                            disabled={(this.state.handle === '' || this.state.handle === null)}
                            fullWidth
                        >
                          {isLoading ? 
                            <CircularProgress className={classes.progress} />
                          :
                            <span>Make Podcast</span>
                          }
                        </Button>
                      </FormControl>
                    </form>
                  </Grid>
                :
                  <Grid container direction="row" justify="center">
                    <Grid item xs={12} className={classes.resultContainer}>
                      <Typography variant="h5" color="inherit" align="center">
                        Your Podcast URL is
                      </Typography> 
                      <Typography className={classes.smallTopMargin} variant="subtitle1" color="inherit" align="center">
                        <a href={podUrl} target="_blank" rel="noopener noreferrer">{podUrl}</a>
                        <CopyToClipboard text={podUrl}
                          onCopy={() => this.setState({copied: true})}>
                          <Button><FileCopy /></Button>
                        </CopyToClipboard>
                      </Typography>
                      <Typography className={classes.smallTopMargin} variant="body1" color="inherit" align="center">
                        Simply paste this URL into your favorite podcast player, and new episodes will download automatically. Enjoy!
                      </Typography>
                      <Typography className={classes.smallTopMargin} variant="subtitle2" align="center">
                        <a
                          href="https://medium.com/@joshmuccio/how-to-manually-add-a-rss-feed-to-your-podcast-app-on-desktop-ios-android-478d197a3770"
                          target="_blank" rel="noopener noreferrer">
                          Need Help?
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.resultContainer}>
                      <Button onClick={this.resetState} color="secondary" variant="contained">
                        <span>Make Another Podcast</span>
                      </Button>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(App);
